import * as React from 'react';
import Game from '../types/Game';

import MapContainerComponent from './MapContainerComponent';
import RoundPrepComponent from './RoundPrepComponent';
import RoundQuestionComponent from './RoundQuestionComponent';
import GameRound from '../types/GameRound';
import { GameRoundState } from '../types/GameRoundState';
import MockGameAPI from '../api/MockGameAPI';
import RoundAnswerComponent from './RoundAnswerComponent';
import ModalComponent from './ModalComponent';
import GameOverComponent from './GameOverComponent';

interface Props {
    
}
interface State {
    game: Game | null;
    currentAnswer: string;
    showModal: boolean;
    modalMessage: string;
    modalTitle: string;
}

export default class GameComponent extends React.Component<Props, State> {
    api: MockGameAPI;
    gameRoundTime: number = 0;
    
    constructor(props: Props) {
        super(props);
        this.api = new MockGameAPI();
        this.state = {
            game: this.api.game,
            currentAnswer: "",
            showModal: false,
            modalMessage: "",
            modalTitle: ""
        };

        this.renderMap = this.renderMap.bind(this);
        this.advance = this.advance.bind(this);
        this.answerQuestion = this.answerQuestion.bind(this);
        this.renderAnswer = this.renderAnswer.bind(this);
        this.renderPrep = this.renderPrep.bind(this);
        this.renderQuestion = this.renderQuestion.bind(this);
        this.answerOnChange = this.answerOnChange.bind(this);
        this.gameTimeTick = this.gameTimeTick.bind(this);
        this.mapClicked = this.mapClicked.bind(this);
        this.helpClicked = this.helpClicked.bind(this);
        this.scoreClicked = this.scoreClicked.bind(this);
    }

    componentDidMount() {
        this.setState({
            game: this.api.startGame()
        });
    }

    render() {
        if (!this.state.game) {
            return "Loading...";
        }
        let game = this.state.game;
        let round = this.state.game.gameRounds[this.state.game.currentStep];

        if (game.isCompleted) {
            return (
                <GameOverComponent 
                    game={game}
                    round={round}
                    advance={() => {}}
                />
            );
        }


        switch(round.state) {
            case GameRoundState.MAP:
                return this.renderMap(game, round);
            case GameRoundState.PREP:
                return this.renderPrep(game, round);
            case GameRoundState.QUESTION:
                return this.renderQuestion(game, round);
            case GameRoundState.ANSWER_CORRECT:
            case GameRoundState.ANSWER_WRONG:
                return this.renderAnswer(game, round);
        }
    }

    renderMap(game: Game, round: GameRound): JSX.Element {
        return (
           <div>
               <MapContainerComponent
                game={game}
                round={round}
                advanceCallback={this.advance}
                mapClicked={this.mapClicked}
                 /> 
                 <ModalComponent 
                    message={this.state.modalMessage}
                    title={this.state.modalTitle}
                    show={this.state.showModal}
                    help={this.helpClicked}
                    dismiss={() => { this.setState({ showModal: false })}}
                    />
           </div>
        )
    }

    renderPrep(game: Game, round: GameRound): JSX.Element {
        return (
            <div>
                <div className="page-content-overlay"></div>
                <RoundPrepComponent 
                    scoreClicked={this.scoreClicked}
                    game={game}
                    round={round}
                    advance={this.advance}
                    gameTimeTick={this.gameTimeTick} />
                <ModalComponent 
                    message={this.state.modalMessage}
                    title={this.state.modalTitle}
                    show={this.state.showModal}
                    help={this.helpClicked}
                    dismiss={() => { this.setState({ showModal: false })}}
                    />
            </div>
        )
    }

    renderQuestion(game: Game, round: GameRound): JSX.Element {
        return (
            <div>
                <RoundQuestionComponent 
                    scoreClicked={this.scoreClicked}
                    game={game}
                    round={round}
                    currentAnswer={this.state.currentAnswer}
                    answerOnChange={this.answerOnChange}
                    answerQuestion={this.answerQuestion}
                    gameTimeTick={this.gameTimeTick} />
                <ModalComponent 
                    message={this.state.modalMessage}
                    title={this.state.modalTitle}
                    show={this.state.showModal}
                    help={this.helpClicked}
                    dismiss={() => { this.setState({ showModal: false })}}
                    />
            </div>
            
        )
    }

    renderAnswer(game: Game, round: GameRound): JSX.Element {
        return (
            <div>
                <RoundAnswerComponent
                    scoreClicked={this.scoreClicked}
                    game={game}
                    round={round}
                    advance={this.advance}
                    gameTimeTick={this.gameTimeTick}
                    />
                <ModalComponent 
                    message={this.state.modalMessage}
                    title={this.state.modalTitle}
                    show={this.state.showModal}
                    help={this.helpClicked}
                    dismiss={() => { this.setState({ showModal: false })}}
                    />
            </div>
            
        );
    }

    helpClicked() {
        window.open("mailto:info@contrastmedialabs.com?subject=Domo Hunt Feedback&message=Enter your feedback here...", "_blank");
    }

    mapClicked() {
        let game = this.state.game;
        if (!game) { return; }
        let round = game.gameRounds[game.currentStep];
        window.open("https://maps.google.com/?q=" + round.location.markerLocation.lat + "," + round.location.markerLocation.lng, "_blank");
    }

    scoreClicked() {
        this.setState({
            modalTitle: "Score Information",
            modalMessage: "Score information goes here.",
            showModal: true
        });
    }

    gameTimeTick(seconds: number) {
        this.gameRoundTime = seconds;
        let game = this.state.game;
        if (!game) { return; }
        let round = game.gameRounds[game.currentStep];
        if (seconds === 0 && round.state === GameRoundState.QUESTION) {
            this.setState({
                game: this.api.answerQuestion("", 0, game, round)
            });
        }
    }

    answerOnChange(e: React.FormEvent<HTMLInputElement>) {
        this.setState({
            currentAnswer: e.currentTarget.value
        })
    }

    answerQuestion(e: any) {
        if (e) {
            e.preventDefault();
        }
        
        let game = this.state.game;
        if (!game) { return; }
        let round = game.gameRounds[game.currentStep];
        this.setState({
            currentAnswer: "",
            game: this.api.answerQuestion(this.state.currentAnswer, this.gameRoundTime, game, round)
        })
    }

    advance() {
        let game = this.state.game;
        if (!game) { return; }
        let round = game.gameRounds[game.currentStep];
        switch(round.state) {
            case GameRoundState.MAP:
                this.setState({
                    game: this.api.advanceRound(game, round, GameRoundState.PREP)
                });
            return;
            case GameRoundState.PREP:
                this.setState({
                    game: this.api.advanceRound(game, round, GameRoundState.QUESTION)
                });
            return;
            case GameRoundState.QUESTION:
                // Handled in answer question
            return;
            case GameRoundState.ANSWER_WRONG:
            case GameRoundState.ANSWER_CORRECT:
                // Handled below
            break;
        }

        this.setState({
            game: this.api.advance()
        });
    }
}