import GameRound from "../types/GameRound";
import Game from "../types/Game";
import User from "../types/User";

import TestGameRounds from '../types/TestGameRounds';
import { GameRoundState } from "../types/GameRoundState";
import GameAnswer from "../types/GameAnswer";

export default class MockGameAPI {

    lastGameRound: GameRound | null;
    gameRounds: GameRound[];

    allGameRounds: GameRound[];

    game: Game;

    user: User;

    constructor() {
        this.lastGameRound = null;
        this.allGameRounds = TestGameRounds;
        this.gameRounds = [this.allGameRounds[0]];
        this.user = new User(
            0,
            "arvinkx",
            "arvinkx@gmail.com",
            "2094990089"
        );
        this.game = new Game(
            0,
            false,
            false,
            this.user,
            0,
            0,
            0,
            this.gameRounds
        );
    }

    startGame(): Game {
        this.game.isStarted = true;
        this.game.estimatedTimeString = (this.allGameRounds.length * 10) + "m";
        return this.game;
    }

    advance(): Game | null {
        // Move game to next step and check for completion
        this.game.currentStep += 1;
        if (this.game.currentStep > this.allGameRounds.length - 1) {
            this.game.currentStep -= 1; // Reset to show last round again
            this.game.isCompleted = true;
            return this.game;
        }
        // Add next round to game and advance.
        let nextRound = this.allGameRounds[this.game.currentStep];
        this.game.gameRounds.push(nextRound);
        this.game.progress = (this.game.currentStep / this.allGameRounds.length);
        this.game.estimatedTimeString = (this.allGameRounds.length - (this.gameRounds.length - 1)) * 10 + "m";
        return this.game;
    }

    advanceRound(game: Game, round: GameRound, state: GameRoundState): Game {
        game.gameRounds.forEach(gameRound => {
            if (gameRound.id === round.id) {
                gameRound.state = state;
            }
        })
        return game;
    }

    answerQuestion(answer: string, timeRemaining: number, game: Game, round: GameRound): Game {
        let gameAnswer = new GameAnswer(-1, answer, timeRemaining);
        let isCorrect = false;
        // Check if answer is correct by using includes 
        // to check for substring
        round.gameQuestion.answerKeywords.forEach(possibleAnswer => {
            if (answer.toLowerCase().includes(possibleAnswer.toLowerCase())) {
                isCorrect = true;
            }
        });

        game.gameRounds.forEach(gameRound => {
            if (gameRound.id === round.id) {
                // Update game with result
                gameAnswer.wasCorrect = isCorrect;
                gameRound.gameAnswer = gameAnswer;
                if (isCorrect) {
                    gameRound.state = GameRoundState.ANSWER_CORRECT;
                    let timeFactor = ((gameRound.gameQuestion.questionTime + timeRemaining) / gameRound.gameQuestion.questionTime) - 1;
                    console.log("Time factor: ", timeFactor);
                    gameAnswer.timeFactor = timeFactor;
                    gameAnswer.totalPoints = Math.ceil(gameRound.gameQuestion.basePointValue + (gameRound.gameQuestion.basePointValue * timeFactor));
                    console.log("Points: ", gameAnswer.totalPoints);
                    game.points += gameAnswer.totalPoints;
                } else if (gameRound.id === round.id) {
                    gameRound.state = GameRoundState.ANSWER_WRONG;
                }
                if ((game.currentStep + 1) >= this.allGameRounds.length) {
                    game.progress = 1;
                    game.estimatedTimeString = "0m";
                } else {
                    game.progress = ((game.currentStep + 1) / (this.allGameRounds.length)); // Show future progress
                    game.estimatedTimeString = (this.allGameRounds.length - (this.gameRounds.length)) * 10 + "m";
                }
            }
        })
        
        return game;
    }

    getCurrentRound(gameId: number): GameRound | null {
        if (gameId === null || gameId === undefined) {
            return null;
        }
        
        let gameStep = this.game.currentStep;
        return this.gameRounds[gameStep];
    }
}