import * as React from 'react';
import Logo from '../images/domohunt_logo.png';


const BrandingContainerComponent: React.FC = (props) => {
    return (
        <div className="branding-container">
            <img src={Logo} alt="Domo District logo" />
        </div>
    )
}

export default BrandingContainerComponent;