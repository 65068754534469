import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';

interface Props {
    title: string;
    message: string;
    show: boolean;
    dismiss: () => void;
    help: () => void;
}

const ModalComponent: React.FC<Props> = (props) => {
    return (
        <Alert className="modal-comp" show={props.show} variant="success">
            <Alert.Heading>{props.title}</Alert.Heading>
            <p>
            {props.message}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={props.dismiss} variant="outline-success">
                    Ok
                </Button>
                <Button onClick={props.help} className="ml-2" variant="outline-success">
                    Send Feedback
                </Button>
            </div>
      </Alert>
    )
}

export default ModalComponent;