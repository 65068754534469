import * as React from 'react';
import Game from '../types/Game';
import GameRound from '../types/GameRound';

import GameNavigationComponent from './GameNavigationComponent';
import CountdownTimer from './TimerComponent';
import PointsComponent from './PointsComponent';
import { Button } from 'react-bootstrap';
import GameProgressComponent from './GameProgressComponent';
import BrandingComponent from './BrandingContainerComponent';
import ImageHintComponent from './ImageHintComponent';

interface Props {
    game: Game;
    round: GameRound;
    gameTimeTick: (seconds: number) => void;
    advance: () => void;
    scoreClicked: () => void;
}

const RoundPrepComponent: React.FC<Props> = (props) => {
    return (
        <div className="round-prep-container">
                <GameNavigationComponent />
                <PointsComponent
                    onClick={props.scoreClicked}
                    game={props.game} />
                <CountdownTimer
                    intervalCallback={props.gameTimeTick}
                    paused={true}
                    seconds={props.round.gameQuestion.questionTime} 
                    />
                <BrandingComponent />
                <div
                    className="round-prep-content"
                >{props.round.gameQuestion.locationText}</div>
                <Button
                    className="prep-advance-btn"
                    onClick={props.advance}>Next</Button>
                <ImageHintComponent
                    imageSrc={props.round.hintImage}
                    imageAlt="" />
                <GameProgressComponent
                    progress={Math.floor(props.game.progress * 100)}
                    timeString={props.game.estimatedTimeString}
                    />
            </div>
    );
}

export default RoundPrepComponent;