import GameQuestion from "./GameQuestion";
import GameAnswer from "./GameAnswer";
import { GameRoundState } from "./GameRoundState";
import GameLocation from "./GameLocation";

export default class GameRound {
    id: number;
    startDate: Date;
    gameQuestion: GameQuestion;
    runningTime: number;
    gameAnswer?: GameAnswer;
    state: GameRoundState;
    location: GameLocation;
    hintImage: string;


    constructor(
        id: number,
        startDate: Date,
        gameQuestion: GameQuestion,
        runningTime: number,
        state: GameRoundState,
        gameLocation: GameLocation,
        hintImage: string,
        gameAnswer?: GameAnswer
    ) {
        this.id = id;
        this.startDate = startDate;
        this.gameQuestion = gameQuestion;
        this.runningTime = runningTime;
        this.gameAnswer = gameAnswer;
        this.state = state;
        this.hintImage = hintImage;
        this.location = gameLocation;
    }
}