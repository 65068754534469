import * as React from 'react';
import Game from '../types/Game';
import GameRound from '../types/GameRound';
import GameNavigationComponent from './GameNavigationComponent';
import PointsComponent from './PointsComponent';
import CountdownTimer from './TimerComponent';
import { Button, Form, FormControl, FormLabel } from 'react-bootstrap';
import GameProgressComponent from './GameProgressComponent';

import BrandingContainerComponent from './BrandingContainerComponent';
import ImageHintComponent from './ImageHintComponent';

interface Props {
    game: Game;
    round: GameRound;
    gameTimeTick: (seconds: number) => void;
    answerQuestion: (e: any) => void;
    answerOnChange: (e: React.FormEvent<HTMLInputElement>) => void;
    currentAnswer: string;
    scoreClicked: () => void;
}

const RoundQuestionComponent: React.FC<Props> = (props: Props) => {
    return (
        <div className="round-question-container">
            <GameNavigationComponent />
            <PointsComponent
                    onClick={props.scoreClicked}
                    game={props.game} />
            <CountdownTimer
                intervalCallback={props.gameTimeTick}
                paused={false}
                seconds={props.round.gameQuestion.questionTime} 
                />
            <BrandingContainerComponent />
            <div
                className="round-question-content">
                <div className="round-question-text">
                    {props.round.gameQuestion.question}
                </div>
                <Form
                onSubmit={props.answerQuestion}>
                <FormLabel
                    className="answer-label">
                        ANSWER
                    </FormLabel>
                <FormControl 
                    type="text" 
                    placeholder="Answer..." 
                    value={props.currentAnswer} 
                    onChange={props.answerOnChange}
                    />
            </Form>
            <Button
                className="prep-advance-btn"
                onClick={props.answerQuestion}>Submit Answer</Button>
            </div>

            <ImageHintComponent
                imageAlt=""
                imageSrc={props.round.hintImage} />
            <GameProgressComponent
                progress={Math.floor(props.game.progress * 100)}
                timeString={props.game.estimatedTimeString}
                />
        </div>
    );
}

export default RoundQuestionComponent;