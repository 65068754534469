import * as React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import MapLocation from '../types/MapLocation';

interface Props {
    google: any;
    markerLocation: MapLocation;
    initialCenter: MapLocation;
    markerName: string;
}

const MapComponent: React.FC<Props> = (props) => {
    return (
        <div className="map">
            <Map
                google={props.google}
                zoom={18}
                initialCenter={props.initialCenter}
                zoomControl={false}
                mapTypeControl={false}
                fullscreenControl={false}
                panControl={false}
                scaleControl={false}
                streetViewControl={false}
                >
                    <Marker
                        name={props.markerName}
                        position={props.markerLocation}
                    />
            </Map>
        </div>
    );
}

//export default MapComponent;

export default GoogleApiWrapper({
    apiKey: "AIzaSyDhU7idw9lbsrmBsPwPT1UR1ImHzNiJ_m0"
})(MapComponent);