
export default class GameAnswer {
    id: number;
    answer: string;
    wasCorrect: boolean = false;
    totalPoints: number = 0;
    timeFactor: number = 0;
    answerTime: number; // Remaining time

    constructor(id: number, answer: string, answerTime: number) {
        this.id = id;
        this.answer = answer;
        this.answerTime = answerTime;
    }
}