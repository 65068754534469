import React from 'react';
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import GameComponent from './components/GameComponent';
import HomepageComponent from './components/HomePageComponent';

const App: React.FC = () => {
  return (
    <Router>
      <div className="">
      
        <Switch>
            <Route path="/about">
              About
            </Route>
            <Route path="/game">
              <GameComponent 

              />
            </Route>
            <Route path="/">
              <HomepageComponent />
            </Route>
          </Switch>
      </div>
    </Router>
  );
}

export default App;
