import * as React from 'react';
import { ProgressBar } from 'react-bootstrap';
import SeparatorComponent from './SeparatorComponent';

interface Props {
    progress: number;
    timeString: string;
}

const GameProgressComponent: React.FC<Props> = (props) => {
    return (
        <div className="progress-comp">

            <SeparatorComponent />

            <h3>GAME PROGRESS</h3>
            <ProgressBar
                now={props.progress}
                label={`${props.progress}%`} />
            <div className="game-time-progress">
                {props.timeString} of game left
            </div>
        </div>
    )
};

export default GameProgressComponent;