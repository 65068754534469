import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import NavigationComponent from './NavigationComponent';
import NavIcon from '../images/nav_icon.png';

interface Props {

}

interface State {

}

export default class GameNavigationComponent extends React.Component<Props, State> {

    render() {
        return (
            <div className="nav-container">
                <Navbar collapseOnSelect expand="lg" fixed="bottom">
                    <div className="nav-toggle-container">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav">
                            <img src={NavIcon} alt="" />
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <NavigationComponent />
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}