import Game from "./Game";

export default class User {
    id: number;
    username: string;
    email: string;
    phone?: string;
    games: Game[];
    constructor(id: number, username: string, email: string, phone?: string) {
        this.id = id;
        this.username = username;
        this.email = email;
        this.phone = phone;
        this.games = [];
    }
}