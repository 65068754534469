import GameQuestion from './GameQuestion';
import GameRound from './GameRound';
import { GameRoundState } from './GameRoundState';

export default [
    new GameRound(
        0,
        new Date(),
        new GameQuestion(1, "Built in 1989, the city’s convention center has hosted many conventions and parties over the years. While many have taken place inside, some have availed themselves of the oft-overlooked terrace extending over the main entry. Two living sentries stand guard over the terrace, their crowns adorned with the splendor of nature. What are they?", "This is some other location text.", "Trees", ["trees"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -1,
            name: "Centre Plaza",
            addressLine1: "1000 L St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.642204,
                lng: -121.001924
            },
            markerLocation: { lat: 37.642204, lng: -121.001924},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/1.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/1.png"
    ),
    
    new GameRound(
        1,
        new Date(),
        new GameQuestion(2, "Search the placards on the ground for Modesto’s most famous son, George Lucas, the creator of Star Wars and Indiana Jones. Facing the plaza, place your feet upon the first and last letters of his first name. The remaining letters can be rearranged to spell a word that can be used to describe a rather heart-stopping scene from Indiana Jones and the Temple of Doom. What is the word?", "This is some other location text.", "Gore", ["gore"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -2,
            name: "Tenth Street Place Plaza",
            addressLine1: "10th St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.640939,
                lng: -121.000634
            },
            markerLocation: { lat: 37.640939, lng: -121.000634},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/2.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/2.png"
    ),
    new GameRound(
        2,
        new Date(),
        new GameQuestion(3, "As you take in the talent of the various artists who have participated in DoMo Walls, one piece will strike you as particularly mind-blowing. What is the professional name of the artist who created this mural?", "This is some other location text.", "Camer1SF", ["camer1sf"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -3,
            name: "Art Alley",
            addressLine1: "Bartoni Ln",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.639981,
                lng: -120.999492
            },
            markerLocation: { lat: 37.639981, lng: -120.999492},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/3.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/3.png"
    ),
    new GameRound(
        3,
        new Date(),
        new GameQuestion(4, "Another mural in the alley depicts a person who some might believe to be a clairvoyant with attitude. What instrument appears to be conspicuously missing from this image?", "This is some other location text.", "Lighter", ["lighter"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -4,
            name: "Art Alley",
            addressLine1: "Bartoni Ln",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.639981,
                lng: -120.999492
            },
            markerLocation: { lat: 37.639981, lng: -120.999492},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/4.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/4.png"
    ),
    new GameRound(
        4,
        new Date(),
        new GameQuestion(5, "Find the address of the Gallo Center for the Arts and stand beneath it. The trellises above-head and to your sides are blanketed by vine tendrils that bear the fruits used to make a drink so ancient, it has been a part of ceremonial life throughout much of human history. Today, the namesake of this building is the largest producer of this beverage in the world. Using the four letters that spell the name of this beverage, create another three-letter word that describes the age of this performing arts center in the context of ancient history.", "This is some other location text.", "New", ["new"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -5,
            name: "Gallo Center for the Arts",
            addressLine1: "1000 I St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.639683,
                lng: -120.998394
            },
            markerLocation: { lat: 37.639683, lng: -120.998394},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/5.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/5.png"
    ),
    new GameRound(
        5,
        new Date(),
        new GameQuestion(6, "Consider the statue of the Native American staring intently at the horizon. You are looking at Chief Estanislaus, for whom Stanislaus County is named. He earned the name of the region that would become Stanislaus County by mounting a successful rebellion against Spanish rule out of Mission San Jose in November 1828, freeing the region east of the San Joaquin river from mission domination. From which direction would the person he is acknowledging with his extended hand be coming? (HINT: Downtown Modesto is oriented on a diagonal grid).", "This is some other location text.", "West (Mission San Jose territory)", ["west"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -6,
            name: "East corner of 11th and I Streets",
            addressLine1: "1101 I St.",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.640250,
                lng: -120.998156
            },
            markerLocation: { lat: 37.640250, lng: -120.998156},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/6.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/6.png"                 
    ),
    new GameRound(
        6,
        new Date(),
        new GameQuestion(7, "Shortly after California became the 31st state in the union, on September 9, 1950, the first county seat of Adamsville was established. How long before Modesto became the permanent county seat?", "This is some other location text.", "17 years", ["17 years", "seventeen years"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -7,
            name: "Center of Courthouse Lawn",
            addressLine1: "N/A",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.640523,
                lng:  -120.997581
            },
            markerLocation: { lat: 37.640523, lng:  -120.997581},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/7.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/7.png"                  
    ),
    new GameRound(
        7,
        new Date(),
        new GameQuestion(8, "Make your way through the courthouse lawn behind the memorials and markers. Look for the sign being swallowed by nature. Its chaste message, abhorrent to most modern sensibilities, will soon disappear for all time. What does it say?", "This is some other location text.", "Reserved for ladies and their escorts", ["Reserved for ladies and their escorts", "answer2"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -8,
            name: "Courthouse Lawn",
            addressLine1: "N/A",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.640523,
                lng: -120.997581
            },
            markerLocation: { lat: 37.640523, lng: -120.997581},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/8.jpg"
        },                  
        "https://www.cmlapps.com/domo_hunt/assets/triangle/8.png"
    ),
    new GameRound(
        8,
        new Date(),
        new GameQuestion(9, "The former El Viejo “The Old” post office was built in 1938 with Italian Renaissance architecture. The building takes much of its ornamentation from nature. Count the flowers near the entrance that are crafted from metal. There are more than you may think. Remember this number as you search for the mythological creature that provides the namesake of one of the houses in the Harry Potter series. Subtract the number of this creature found from the total number of flowers counted to determine the numeric answer.", "This is some other location text.", "42", ["42", "forty two"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -9,
            name: "El Viejo Post Office",
            addressLine1: "1125 I St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.641174,
                lng: -120.997716
            },
            markerLocation: { lat: 37.641174, lng: -120.997716},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/9.jpg"
        },                  
        "https://www.cmlapps.com/domo_hunt/assets/triangle/9.png"
    ),
    new GameRound(
        9,
        new Date(),
        new GameQuestion(10, "The Elks Building, constructed in 1925, originally housed a professional boxing ring in its basement. The boxing ring was donated and dedicated by the contemporary heavyweight champion of the world at the time. Who was he? (You may need to look this one up on your phone. It’s okay; we’re not looking)", "This is some other location text.", "Jack Dempsey", ["jack dempsey", "jack", "dempsey"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -10,
            name: "Elks Building",
            addressLine1: "1220 I St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.641558,
                lng: -120.996483
            },
            markerLocation: { lat: 37.641558, lng: -120.996483},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/10.jpg"
        },                  
        "https://www.cmlapps.com/domo_hunt/assets/triangle/10.png"
    ),
    new GameRound(
        10,
        new Date(),
        new GameQuestion(11, "The walls that bookend the entrance to the McHenry Museum tell the story of the building’s original function as a public library. A creature prominently displayed on the outside of the museum would have been undoubtedly featured in some of the literature carried within. What is the creature?", "This is some other location text.", "Lion", ["lion"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -11,
            name: "McHenry Museum",
            addressLine1: "1402 I St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.642420,
                lng: -120.994914
            },
            markerLocation: { lat: 37.642420, lng: -120.994914},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/11.jpg"
        },                  
        "https://www.cmlapps.com/domo_hunt/assets/triangle/11.png"
    ),
    new GameRound(
        11,
        new Date(),
        new GameQuestion(12, "The paperboy statue was donated to the City along with the adjacent park in 1995. The statue reflects the activities of a bygone era in nostalgic fashion. Stand on the park side of the statue near the dog’s head and peer through the gap created by the throwing motion of the boy’s arm. You’ll find a sign directing you to the next location on the scavenger hunt. What is it?", "This is some other location text.", "McHenry Mansion", ["McHenry Mansion", "answer2"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -12,
            name: "West corner of 15th and I Streets",
            addressLine1: "1421 I St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.643151,
                lng: -120.994623
            },
            markerLocation: { lat: 37.643151, lng: -120.994623},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/12.jpg"
        },                  
        "https://www.cmlapps.com/domo_hunt/assets/triangle/12.png"
    ),
    new GameRound(
        12,
        new Date(),
        new GameQuestion(13, "Constructed in 1883, the McHenry Mansion has seen a lot over the years. The house stands still, like a steadfast witness to its Victorian-era roots. But one part of the house is always changing. One might even say it’s temperamental or sways with the wind. What is it?", "This is some other location text.", "Weather Vane", ["Weather Vane", "answer2"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -13,
            name: "McHenry Mansion",
            addressLine1: "906 15th St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.643668,
                lng: -120.994519
            },
            markerLocation: { lat: 37.643668, lng: -120.994519},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/13.jpg"
        },                  
        "https://www.cmlapps.com/domo_hunt/assets/triangle/13.png"
    ),
    new GameRound(
        13,
        new Date(),
        new GameQuestion(14, "Dewz restaurant and wine bar staff use a variety of tools to serve the fine faire offered to patrons every day. What opening tool near the restaurant’s entrance may be used to secure a ride home for guests who imbibe a little too much of the establishment’s extensive wine selection?", "This is some other location text.", "Corkscrew", ["corkscrew"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -14,
            name: "Dewz Restaurant",
            addressLine1: "1505 J St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.644448,
                lng: -120.995480
            },
            markerLocation: { lat: 37.644448, lng: -120.995480},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/14.jpg"
        },                                  
        "https://www.cmlapps.com/domo_hunt/assets/triangle/14.png"  
    ),
    new GameRound(
        14,
        new Date(),
        new GameQuestion(15, "Built in the 1930’s, the State Theatre is a beautiful example of art deco architecture. Birthed in the opulence of the 1920’s, this style of architecture often incorporates metallic colors representing precious metals, like silver and gold. Find the gold leaf numerals prominently displayed at the entrance to the building. By rearranging and reorienting these numerals, it’s possible to spell a word that describes the affection the community holds for this historic gem. What is it?", "This is some other location text.", "LOVE", ["love"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -15,
            name: "State Theater",
            addressLine1: "1307 J St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.642998,
                lng: -120.997273
            },
            markerLocation: { lat: 37.642998, lng: -120.997273},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/15.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/15.png"                
    ),
    new GameRound(
        15,
        new Date(),
        new GameQuestion(16, "This is a holding place for one piece of trivia that still needs to be developed.", "This is some other location text.", "Holding place", ["holding place", "answer2"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -16,
            name: "West corner of 12th and I Streets",
            addressLine1: "12th St & I St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.640807,
                lng: -120.997240
            },
            markerLocation: { lat: 37.640807, lng: -120.997240},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/16.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/16.png"                  
    ),
    new GameRound(
        16,
        new Date(),
        new GameQuestion(17, "The utility box before you is the last you’ll pass of those commissioned as pieces of public art by the Downtown Modesto Partnership. Did you remember to keep count along the way? How many total utility boxes murals did you pass?", "This is some other location text.", "6", ["six", "6"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -17,
            name: "North corner of 11th and I St",
            addressLine1: "11th St & I St",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.640403,
                lng: -120.998233
            },
            markerLocation: { lat: 37.640403, lng: -120.998233},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/17.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/17.png"             
    ),
    new GameRound(
        17,
        new Date(),
        new GameQuestion(18, "The hands before you tell the time, the short hand the hour and the long the minute. They point to other things throughout the day – the sky, the earth and even passersby. How many times a day do they point to the date of their birth?", "This is some other location text.", "4", ["four", "4"], 10, 30),
        0,
        GameRoundState.MAP,
        {
            id: -18,
            name: "Flower Clock",
            addressLine1: "Downtown",
            addressLine2: "",
            city: "Modesto",
            state: "CA",
            zipCode: "95354",
            mapCenter: {
                lat: 37.641825,
                lng: -121.001304
            },
            markerLocation: { lat: 37.641825, lng: -121.001304},
            image: "https://www.cmlapps.com/domo_hunt/assets/location_images/19.jpg"
        },
        "https://www.cmlapps.com/domo_hunt/assets/triangle/19.png"                  
    )
    
]