import * as React from 'react';

import Game from '../types/Game';

import ScoreBg from '../images/score_triangle.png';

interface Props {
    game: Game | null;
    onClick: () => void;
}

const PointsComponent: React.FC<Props> = (props: Props) => {
    if (!props.game) {
        return null;
    }
    return (
        <div className="score-container" onClick={props.onClick}>
            <img src={ScoreBg} alt="" className="score-bg" />
            <div className="score-text-container">
                <div className="score-label">SCORE</div>
                <div className="score-text">{props.game.points}</div>
            </div>
        </div>
    )
}

export default PointsComponent;