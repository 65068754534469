import * as React from 'react';
import { Navbar } from 'react-bootstrap';

import LogoImage from '../images/domohunt_logo.png';
import NavigationComponent from './NavigationComponent';
import NavIcon from '../images/nav_icon.png';

interface Props {

}

interface State {

}

export default class StandardNavigationComponent extends React.Component<Props, State> {

    render() {
        return (
            <div className="nav-container">
                <Navbar bg="white" collapseOnSelect expand="lg" fixed="top">
                <div className="nav-toggle-container">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav">
                        <img src={NavIcon} alt="" />
                    </Navbar.Toggle>
                </div>
                <Navbar.Brand href="#home" className="mr-auto">
                    <img 
                    className="std-brand-logo"
                    src={LogoImage} alt="Domo District logo" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <NavigationComponent />
                </Navbar.Collapse>
            </Navbar>
            </div>
        )
    }
}