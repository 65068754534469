import * as React from 'react';
import Game from '../types/Game';
import GameRound from '../types/GameRound';
import GameNavigationComponent from './GameNavigationComponent';
import PointsComponent from './PointsComponent';
import BrandingContainerComponent from './BrandingContainerComponent';
import { Button } from 'react-bootstrap';
import ImageHintComponent from './ImageHintComponent';
import GameProgressComponent from './GameProgressComponent';

interface Props {
    game: Game;
    round: GameRound;
    advance: () => void;
}

const GameOverComponent: React.FC<Props> = (props: Props) => {
    return (
        <div className="game-over-container">
            <GameNavigationComponent />
            <PointsComponent
                onClick={() => {}}
                game={props.game} />
            <BrandingContainerComponent />
            <div className="round-answer-content">
                <h3>Game Over</h3>
                <div className="go-score-container">
                    <div className="go-score-label">YOUR SCORE</div>
                    <h3>{props.game.points}</h3>
                </div>
            </div>
            <Button
                className="prep-advance-btn"
                onClick={props.advance}>Done</Button>
            <ImageHintComponent
                imageAlt=""
                imageSrc={props.round.hintImage} />
            <GameProgressComponent
                progress={Math.floor(props.game.progress * 100)}
                timeString={props.game.estimatedTimeString} />
        </div>
    )
}

export default GameOverComponent;