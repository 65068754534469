import * as React from 'react';

import TimerBg from '../images/timer_triangle.png';

interface Props {
    seconds: number;
    paused: boolean;
    intervalCallback: (seconds: number) => void;
}

interface State {
    seconds: number;
}

export default class CountdownTimer extends React.Component<Props, State> {

    interval: any;

    constructor(props: Props) {
        super(props);
        this.state = { seconds: props.seconds };

        this.tick = this.tick.bind(this);
    }

    tick() {
        if (this.interval == null) return;
        if (this.props.paused) return;
        let displayTime = this.state.seconds - 1;
        if (displayTime <= 0) {
            displayTime = 0;
        }
        
        this.setState(({
            seconds: displayTime
        }));

        this.props.intervalCallback(displayTime);
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.interval = null;
    }

    render() {
        let timerText = "0:" + this.state.seconds
        if (this.state.seconds > 59) {
            timerText = "1:" + (this.state.seconds - 60);
        }
        if (this.state.seconds < 10) {
            timerText = "0:0" + this.state.seconds;
        }
        return (
            <div className="timer-container">
                <img src={TimerBg} alt="" className="timer-bg" />
                <div className="timer-text-container">
                    <div className="timer-label">
                        TIME
                    </div>
                    <div className="timer-text">
                        {timerText}
                    </div>
                </div>
            </div>
        );
    }
}