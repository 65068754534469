import * as React from 'react';
import MapComponent from './MapComponent';
import Game from '../types/Game';
import GameRound from '../types/GameRound';

import {Button, Container, Row, Col} from 'react-bootstrap';
import StandardNavigationComponent from './StandardNavigationComponent';
import NavIcon from '../images/directions_icon.png';

interface Props {
    game: Game,
    round: GameRound, 
    advanceCallback: () => void;
    mapClicked: () => void;
}

const MapContainerComponent: React.FC<Props> = (props) => {
    let overlayTitle = "First Location";
    let buttonTitle = "Start Game";
    if (props.game && props.game.gameRounds.length > 1) {
        overlayTitle = "Next Location";
        buttonTitle = "Continue Game";
    }
    return (
        <div className="map-container">
                <StandardNavigationComponent />
                <div className="map-container">
                    <MapComponent
                        markerName={props.round.location.name}
                        initialCenter={props.round.location.mapCenter}
                        markerLocation={props.round.location.markerLocation}
                        />
                    <Button
                        className="map-nav-btn"
                        onClick={props.mapClicked}
                        >
                            <img src={NavIcon} alt="navigation directions icon" />
                        </Button>
                </div>
                <div className="map-overlay" onClick={props.advanceCallback}>
                    <h4 className="map-overlay-title">{overlayTitle}</h4>
                    <Container>
                        <Row noGutters>
                            <Col xs={{ span: 5, offset: 1}}>
                                <img className="location-image" src={props.round.location.image} alt={props.round.location.name} />
                            </Col>
                            <Col xs={{ span: 5, offset: 0}} className="location-info">
                                <h4 
                                    className="location-name"
                                >{props.round.location.name}</h4>
                                <div className="location-text">{props.round.location.addressLine1} {props.round.location.addressLine2}</div>
                                <div className="location-text">{props.round.location.city}, {props.round.location.state} {props.round.location.zipCode}</div>
                            </Col>
                        </Row>
                    </Container>
                    <Button
                        className="advance-btn"
                        >{buttonTitle}</Button>
                </div>
                
            </div>
    )
};

export default MapContainerComponent;