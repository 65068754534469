import * as React from 'react';
import { Nav } from 'react-bootstrap';

interface Props {

}

const NavigationComponent: React.FC<Props> = (props) => {
    return (
        <Nav>
            
        </Nav>
    )
};

export default NavigationComponent;