
export default class GameQuestion {
    id: number;
    question: string;
    locationText: string;
    displayAnswer: string;
    answerKeywords: string[];
    basePointValue: number;
    questionTime: number;

    constructor(
        id: number,
        question: string,
        locationText: string,
        displayAnswer: string,
        answerKeywords: string[],
        basePointValue: number,
        questionTime: number
    ) {
        this.id = id;
        this.question = question;
        this.locationText = locationText;
        this.displayAnswer = displayAnswer;
        this.answerKeywords = answerKeywords;
        this.basePointValue = basePointValue;
        this.questionTime = questionTime;
    }

}