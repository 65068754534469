import * as React from 'react';

interface Props {
    imageSrc: string;
    imageAlt: string;
}

const ImageHintComponent:React.FC<Props> = (props: Props) => {
    return (
        <div className="image-hint-container">
            <img src={props.imageSrc} alt={props.imageAlt} />
        </div>
    );
}

export default ImageHintComponent;