import * as React from 'react';
import Game from '../types/Game';
import GameRound from '../types/GameRound';
import PointsComponent from './PointsComponent';
import { Button } from 'react-bootstrap';
import GameProgressComponent from './GameProgressComponent';
import GameNavigationComponent from './GameNavigationComponent';
import BrandingContainerComponent from './BrandingContainerComponent';
import ImageHintComponent from './ImageHintComponent';

interface Props {
    game: Game;
    round: GameRound;
    gameTimeTick: (seconds: number) => void;
    advance: () => void;
    scoreClicked: () => void;
}

const RoundAnswerComponent: React.FC<Props> = (props: Props) => {
    if (!props.round.gameAnswer) {
        return (<div>Game Answer Empty.</div>);
    }
    let correct = (
        <h3>Wrong Answer!</h3>
    );
    if (props.round.gameAnswer?.wasCorrect) {
        correct = (
            <h3>Correct Answer!</h3>
        )
    }
    return (
        <div className="round-answer-container">
            <GameNavigationComponent />
            <PointsComponent
                onClick={props.scoreClicked}
                game={props.game} />
            
            <BrandingContainerComponent />
            <div
                className="round-answer-content">
                {correct}
                <div className="round-answer-display">
                    Answer: {props.round.gameQuestion.displayAnswer}
                </div>
                <div className="round-answer-question-text">
                    <div className="round-answer-question-text-label">QUESTION</div>
                    <div className="round-answer-question-text">
                        {props.round.gameQuestion.question}
                    </div>
                </div>
            </div>
            <Button
                className="prep-advance-btn"
                onClick={props.advance}>Next</Button>
            <ImageHintComponent
                imageAlt=""
                imageSrc={props.round.hintImage} />
            <GameProgressComponent
                progress={Math.floor(props.game.progress * 100)}
                timeString={props.game.estimatedTimeString}
                />
        </div>
    )
}

export default RoundAnswerComponent;