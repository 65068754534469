import User from '../types/User';
import GameRound from './GameRound';

export default class Game {
    id: number;
    isStarted: boolean;
    isCompleted: boolean;
    user: User;
    progress: number;
    estimatedTimeString: string = "";
    currentStep: number;
    points: number;
    gameRounds: GameRound[];

    constructor(
        id: number,
        isStarted: boolean,
        isCompleted: boolean,
        user: User,
        progress: number,
        currentStep: number,
        points: number,
        gameRounds: GameRound[]
    ) {
        this.id = id;
        this.isStarted = isStarted;
        this.isCompleted = isCompleted;
        this.user = user;
        this.progress = progress;
        this.currentStep = currentStep;
        this.points = points;
        this.gameRounds = gameRounds;
    }
}