import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

interface Props {

}

interface State {

}

export default class HomePageComponent extends React.Component<Props, State> {


    render() {
        return (
            <div className="home-page-container">
                <h2>Homepage</h2>
                <br /><br />
                <Button variant="outline-success"><Link to={"/game"}>Play Game</Link></Button>
            </div>
        )
    }
}